import React from 'react';
import logo from './logo.svg';
import './App.css';
import ResponsiveAppBar from './ResponsiveAppBar';
import Preguntas from './Preguntas';

function App() {
  return (
    <div className="App">
      <ResponsiveAppBar/>
      <Preguntas/>
    </div>
  );
}

export default App;
