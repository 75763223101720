import Grid from '@mui/material/Grid'; // Grid version 1
import theme1 from './themes/tema1';
import { ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import oto from './img/oto.jpg';
import cmorlccc from './img/cmorlccc.png';
import apnea from './img/apnea.png';
import sociedadsueño from './img/sociedadsueño.jpeg';
import logo from './img/HA2.png'
import logo2 from './img/sol.webp'
import logo3 from './img/luna.png'

import Avatar from '@mui/material/Avatar';

function Preguntas(){

    


    return (
        <ThemeProvider theme={theme1}>
                <Grid container>
                <Grid xs={12} mt={5}>

   
        
            <Typography fontSize={50} fontWeight={'bold'}>
              Roncar durante el sueño no es normal
            </Typography>
            <Divider/>
      </Grid>

     
            <Grid container xs={12}>
                      <Grid item xs={12} md={4} p={3}>
                        

            <Typography fontSize={25}>
¿Tú o alguno de tus familiares ronca?
            </Typography>
            </Grid>
            <Grid item xs={12} md={4}  p={3}>

            <Typography fontSize={25} >
¿Has notado o te han dicho que haces pausas al respirar durante el sueño?
            </Typography>
            </Grid>
            <Grid item xs={12} md={4} p={3} >

            <Typography fontSize={25} >
            ¿Te cuesta trabajo concentrarte, te duele la cabeza al despertar o tienes somnolencia durante el día?            </Typography>
            </Grid> </Grid>

             </Grid>
             <div id='Apnea del sueño'></div>

             <Grid  bgcolor={'#005187'} color={'white'} p={2}    >
             <Typography fontSize={30} >
Puedes ser portador de <b>Apnea del sueño</b>.
            </Typography>
            

            <Typography fontSize={20}>
            La apnea del sueño es la interrupción parcial o total del paso del aire a través de la vía aérea superior (garganta), lo que provoca una reducción en los niveles de oxígeno y fragmenta tu descanso, causando síntomas como:
            </Typography>

<Grid container spacing={2} mt={2} textAlign={'left'}>
<Grid item xs={12} md={4}  ml={'auto'}  mr={'auto'}>
<Avatar
  alt="ha"
  src={logo2}
  sx={{ width: 56, height: 56,margin:'auto' }}
/> 
        <Typography component="div">
          - Somnolencia durante el día.
        </Typography>
        <Typography  component="div">
          - Fatiga/cansancio no justificado.
        </Typography>
        <Typography  component="div">
          - Dolor de cabeza.
        </Typography>
        <Typography  component="div">
          - Falta de concentración.
        </Typography>
        <Typography  component="div">
          - Cambios de humor.
        </Typography>
        <Typography  component="div">
          - Elevación de la glucosa.
        </Typography>
        <Typography  component="div">
          - Dificultad para bajar de peso.
        </Typography>
        <Typography  component="div">
          - Pérdida del libido.
        </Typography>


            </Grid>

            <Grid item xs={12} md={4}  ml={'auto'}  mr={'auto'}>

            <Avatar
  alt="ha"
  src={logo3}
  sx={{ width: 56, height: 56,margin:'auto' }}
/> 
<Typography  component="div">
          - Ronquidos.
        </Typography>
        <Typography  component="div">
          - Sueño inquieto.
        </Typography>
        <Typography  component="div">
          - Pausas respiratorias (ahogamiento).
        </Typography>
        <Typography  component="div">
          - Urgencia para orinar durante la noche.
        </Typography>
        <Typography  component="div">
          - Sudoraciones nocturnas.
        </Typography>
 


</Grid></Grid>


             </Grid>

             <div id='Sintomas'></div>

             <Grid container >
<Grid item xs={12} md={5} ml={'auto'} mr={'auto'}>
<Typography fontSize={30} m={2} >
¿Cómo saber si tengo apnea del sueño?</Typography>

<Typography fontSize={20} textAlign={'left'}>
- Al visitar a tu médico, se realiza un cuestionario del sueño que orienta el diagnóstico.</Typography>

<Typography fontSize={20} textAlign={'left'}>
- Mediciones corporales, del cuello, de la cavidad nasal y de la cavidad oral.
</Typography>

<Typography fontSize={20} textAlign={'left'}>
- Estudio de sueño, que se realiza en la casa del paciente para determinar la presencia de la apnea del sueño.</Typography>
</Grid> 

<div id='Recuperación'></div>

<Grid item xs={12} md={5} ml={'auto'} mr={'auto'}>
<Typography fontSize={30} m={2} >
¿Cómo puede mejorar y curarse la apnea del sueño?</Typography>
<Typography fontSize={20} textAlign={'left'}>
Individualizando cada caso se pueden ofrecer diferentes opciones de tratamiento desde:</Typography>
<Typography fontSize={20} textAlign={'left'} fontWeight={'bold'}>
- Uso de CPAP.</Typography>
<Typography fontSize={20} textAlign={'left'} fontWeight={'bold'}>
- Cirugía de ronquido (paladar y base de lengua).</Typography>
<Typography fontSize={20} textAlign={'left'}>
- Apoyos de nutriólogo.</Typography>
<Typography fontSize={20} textAlign={'left'}>
- Apoyo odontológico(Sistemas de avance mandibular).</Typography>
<Typography fontSize={20} textAlign={'left'}>
- Apoyo de cirugía maxilofacial.</Typography>
<Typography fontSize={20} textAlign={'left'}>
- Apoyo de neumología   .</Typography>
</Grid>                
</Grid>

<div id='Especialista'></div>

<Grid container xs={12}  ml={'auto'} mr={'auto'} p={4}>
    <Grid item xs={12}>


<Typography fontSize={30} m={2} >
Dr. Luis Alfonso Franco Suazo</Typography>
    </Grid>


    <Grid item xs={12} md={3} mt={1}>
<Card sx={{ maxWidth: 345,height:300,margin:'auto' }}>
        <CardMedia
          component="img"
          height="140"
          image={oto}
          alt="otorrinolaringólogo"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Medico otorrinolaringólogo.
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Numero de cedula: 5295257
          </Typography>
        </CardContent>
    </Card>
    </Grid>
    <Grid item xs={12} md={3} mt={1}>

    <Card sx={{ maxWidth: 345,height:300,margin:'auto' }}>
        <CardMedia
          component="img"
          height="140"
          image={cmorlccc}
          alt="ORLCCC"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Certificado por el Consejo Mexicano de ORL y CCC.
          </Typography>
          <Typography variant="body2" color="text.secondary">
          FRS-1923/07
          </Typography>
        </CardContent>
    </Card>
</Grid>
<Grid item xs={12} md={3} mt={1}>

<Card sx={{ maxWidth: 345,height:300,margin:'auto' }}>
        <CardMedia
          component="img"
          height="140"
          image={apnea}
          alt="apnea"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Diplomado en Desórdenes Respiratorios del Domir, Ronquido y Rinología Aplicada.</Typography>

        </CardContent>
    </Card>
</Grid>
<Grid item xs={12} md={3} mt={1}>

<Card sx={{ maxWidth: 345,height:300,margin:'auto' }}>
        <CardMedia
          component="img"
          height="140"
          image={sociedadsueño}
          alt="sociedadsueño"
          
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
Miembro de la sociedad mexicana del sueño.</Typography>
          <Typography variant="body2" color="text.secondary">
          SOMIMS2326-014
          </Typography>
        </CardContent>
    </Card>
</Grid>
</Grid>            
<div id='Contacto'></div>
<Grid container bgcolor={'#3c3c3c'} color={'white'} p={2} >
    
    <Grid item xs={12} md={6}>
<Typography fontSize={30} fontWeight={'bold'}>Contacto</Typography>
<Typography fontSize={25}><Avatar
  alt="ha"
  src={logo}
  sx={{ width: 56, height: 56,margin:'auto' }}
/> Hospital de Especialidades El Angel</Typography>
<Typography>Dirección: C. Juan N. López 66, La Magdalena, 60080 Uruapan, Michoacán.
</Typography>
<Typography fontSize={20}>Extension. <b>250</b> o <b>251</b> en cualquier de los siguientes numeros:</Typography>
<Typography fontSize={20}>4525248030 </Typography>
<Typography fontSize={20}>4525248029 </Typography>
<Typography fontSize={20}>4525248032 </Typography>
<Typography fontSize={20}>4525248033 </Typography>
<Typography fontSize={20}>4525261260 </Typography>
<Typography fontSize={20}>4525252273 </Typography>

    </Grid>

    <Grid item xs={12} md={6} mt={2}>
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15052.3657918022!2d-102.0564332!3d19.4084544!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842de26e6f96bf97%3A0xd5f9318271e55153!2sHospital%20de%20Especialidades%20El%20Angel!5e0!3m2!1ses-419!2smx!4v1713740484106!5m2!1ses-419!2smx" 
    width="100%" loading="lazy" height={'100%'} ></iframe>
</Grid>
    
    <Grid xs={12} mt={4}><Typography textAlign={'center'}>Ronquido Uruapan ®</Typography></Grid>
    </Grid>    
          

            </ThemeProvider>);

}

export default Preguntas;