// themes.js

import { createTheme } from '@mui/material/styles';

const theme1 = createTheme({
  palette: {
    primary: {
      main:'#343434',
    },
    secondary:{
      main:'#666666',
    },
    
  },
  // Add other theme configurations if needed
  typography: {
    fontFamily: 'Roboto, sans-serif',
    // Add other typography configurations if needed
  },
});

export default theme1;
